<script setup lang="ts">
import type { LinkWithTitle } from '@glow/shared/utils/types';

const props = withDefaults(
  defineProps<{
    title: string;
    links?: LinkWithTitle[];
  }>(),
  { links: () => [] }
);
</script>

<template>
  <div class="link-group">
    <div class="font-bold">
      {{ props.title }}
    </div>
    <hr class="link-group__separator" />
    <div class="link-group__links">
      <NuxtLink
        v-for="[index, link] in props.links.entries()"
        :key="index"
        class="link-group__link"
        :to="link.link"
      >
        {{ link.title }}
      </NuxtLink>
    </div>
  </div>
</template>

<style scoped lang="scss">
.link-group {
  display: flex;
  flex-direction: column;
  gap: var(--app-spacing-3);

  &__separator {
    width: 100%;
    border: none;
    border-top: 1px solid #e0e0e0;
  }

  &__links {
    /* https://stackoverflow.com/a/33899301 */
    display: grid;
    grid-auto-flow: column;
    grid-template-rows: repeat(9, auto);
    gap: var(--app-spacing-2) var(--app-spacing-6);
  }

  &__link {
    font-size: 14px;
    color: var(--app-color-black);
    line-height: 20px;
  }
}
</style>
